import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Example,
	Header,
	Code,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { BigSmall } from "./big-small";

export const Meta: DocPageMeta = {
	category: "Text",
	notepad: "https://hackmd.io/zscZP2eKT3iocw9HjQl4MA",
};

export default (): JSX.Element => (
	<>
		<HeaderSection title="BigSmall" subTitle="Display primary and secondary text. " />
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={BigSmall} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The <Code>BigSmall</Code>component displays paired information with a clear visual
					hierarchy. The primary message (Big text) is emphasized, while the secondary details
					(Small text) provide context.
				</Paragraph>
				<Paragraph>
					The relationship between the Big and Small text should be clear and intuitive. For
					example, a widget summarizing page view in Analytics.
				</Paragraph>
				<Paragraph>Big: 605,183</Paragraph>
				<Paragraph>Small: 18.5%</Paragraph>
				<Paragraph>
					In this example, the "Big" text instantly communicates the total number of page views,
					while the "Small" text provides context with the percentage change compared to a previous
					period. This allows users to quickly grasp the essential information while offering
					additional detail at a glance.
				</Paragraph>
				<Example fn={defaultExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={BigSmall}
					initialProps={{ big: "Big text goes here", small: "Small text goes here" }}
				/>

				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>BigSmall</Code> when:
					</Paragraph>
					<Ul
						items={[
							"The primary information is the most crucial and needs immediate attention.",
							"Space constraints require concise information display.",
							"A clear visual hierarchy enhances the user's understanding of the relationship between data points.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>BigSmall</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								Tables & List Tables: Show a numeric value (Big) with its percentage (Small), or a
								metric (Big) with a unit (Small).
							</>,
							<>Dashboard Widgets: Display a key metric (Big) and its trend or change (Small).</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>BigSmall</Code> to existing components for visual consistency.
							</>,
							<>
								<b>Typography</b>:
								<Ul
									items={[
										<>
											Big Text: Use a larger font size (e.g., <Code>$font-size--small</Code>) for
											emphasis.
										</>,
										<>
											Small Text: Use a smaller font size (e.g., <Code>$font-size--x-small</Code>)
											for supporting details.
										</>,
									]}
								/>
							</>,
							<>
								<b>Color</b>:
								<Ul
									items={[
										<>
											Big Text: Use a darker color (e.g., <Code>$color--text--static--body</Code>)
											for better contrast.{" "}
										</>,
										<>
											Small Text: Use a lighter, less saturated color (e.g.,{" "}
											<Code>$color--gray--dark</Code>) to de-emphasize secondary information.
										</>,
									]}
								/>
							</>,
							<>
								<b>Spacing</b>: Ensure adequate spacing between the Big and Small text elements for
								readability.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Both pieces of information have equal importance. Consider a component with equal-sized text.",
							"The secondary information is lengthy or requires detailed explanation.",
							<>
								You need to summarize a table row. Use the summary variant of the{" "}
								<GatsbyLink to="/lab/components/Tables%20and%20lists/Table">Table</GatsbyLink>{" "}
								component instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul items={["Use sufficient color contrast between text and background."]} />
					<Header.H4>For developers</Header.H4>
					<Ul
						items={[<>This component comes with built-in accessibility, no extra work required.</>]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Keep it concise and focused on the essential details.",
							"Use consistent structure for lists or similar items in the Small text.",
							"Use sentence case for both the Big and Small text.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => <BigSmall big="Big text goes here" small="Small text goes here" />;
